// 引入
@import "./_include/_include.sass"

// footer
.footer-widgets,.site-footer
  background: transparent
  
.site-footer
  background-color: #FFFFFF
  

#footer-widgets
  padding: 0
  box-shadow: 0px -3px 6px #00000029
  .row
    position: static

  .container
    width: calc(100% - 192px)
    position: static
    +mobile
      width: calc(100% - 60px)
  .footer-widget.widget_nav_menu
    padding: 0
    margin: 0
    .menu
      display: flex
      align-items: center
      // justify-content: space-between
      flex-wrap: wrap
      margin: 0
      +tablet
        // display: block
        margin-bottom: 10px
        justify-content: center
      // default
      li
        margin: 0
        a
          display: block
          white-space: nowrap
          +font(.875,$black,0)
            text-decoration: none
            text-align: center
            font-weight: bold
          span
            display: block
      // 第一層
      &>li  
        position: relative
        padding: 30px 0
        margin-left: 30px
        +tablet
          width: 100%
          margin-left: 0
          margin-right: 0
          padding: 0
          margin-bottom: 10px
        &:first-of-type
          margin-left: 0
        &.float-icons
          // margin-left: 15px
          margin-right: -15px
          padding: 15px 0
          width: fit-content
          +tablet
            margin: 0 10px
          img
            +size(30px)
            display: block
        &.wpmenucartli,
        &.logout,
        &.member
          display: none
        &.menu-item-has-children
          // &>a
          //   &:after
          //     content: "+"
          //     font-style: inherit
          //     -webkit-font-smoothing: antialiased
          //     -moz-osx-font-smoothing: grayscale
          //     display: inline-block
          //     text-rendering: auto
          //     font-weight: 400
          //     display: none
          //     +tablet
          //       display: block
          &.open
            // &>a:after
            //   content: "-"
            &>a
              margin-bottom: 10px
            .sub-menu
              display: block
          &:hover>.sub-menu
            +min(767px)
              display: block
        &:last-of-type
          margin-right: 0
        &>a
          +tablet
            margin-bottom: 0
            border-bottom-color: #ccc
        // 第二層
        ul.sub-menu
          display: none
          position: absolute
          bottom: 100%
          left: 50%
          transform: translateX(-50%)
          background-color: #fff
          width: 150px
          +tablet
            background-color: $main
            padding: 5px 0
            position: relative
            width: 100%
            bottom: 0
          li
            a
              padding: 5px 0
              +tablet
                color: #fff
              &:hover
                background-color: $main
                color: #fff


.light-box-img
  display: none
  padding: 60px 0
  position: fixed
  top: 50%
  right: 50%
  transform: translate(50%,-50%)
  z-index: 100
  background-color: #fff
  border: solid 9px #BCBCBC
  width: 90%
  max-width: 720px
  img
    display: block
    max-width: 355px
    width: 90%
    margin: 0 auto
  .cls
    +size(25px)
    padding: 0
    border: none
    background: none
    position: absolute
    top: 30px
    right: 30px
    span
      display: block
      position: relative
      +size(100%,2px)
      &:before,&:after
        content: ""
        display: block
        +size(100%)
        border-radius: 50px
        position: absolute
        top: 0
        left: 0
        transform-origin: 50% 50%
        background-color: $black
      &:before
        transform: rotate(45deg)  
      &:after
        transform: rotate(-45deg)  

#footer-1
  display: flex
  flex-wrap: wrap
  align-items: center
  position: static
  padding-bottom: 43px
  .widget
    margin: 0
    width: 100%
    &:nth-of-type(1)
      width: 32%
      +tablet
        width: 100%
        padding: 30px 0
    &:nth-of-type(2)
      width: 68%
      +tablet
        width: 100%
    a.logo
      display: block
      max-width: 290px
      padding-bottom: 0px
      width: 90%
      +tablet
        margin: 0 auto
    .copyright
      background-color: $main
      width: 100%
      position: absolute
      left: 0
      bottom: 0
      text-align: center
      padding: 10px 0
      p,a
        +font(0.875,#fff,0)