// 引入
@import "./_include/_include.sass"

[id^="module-pg-project"]
  .kc-container.kc-row-container
    overflow: hidden
    +container

  .kc-col-inner-container
    overflow: hidden

  .project-row
    padding: 0 10px
    margin-bottom: -26px
    display: flex
    flex-wrap: wrap
    +tablet
      padding: 0
      margin-left: -10px
      min-width: calc(100% + 20px)
    .kc_text_block.menu
      padding: 0
      margin-bottom: 30px
      width: 25%
      +tablet
        margin-bottom: 0
        width: 100%
      ul
        max-width: 200px
        width: 100%
        margin: 0
        padding: 0
        +tablet
          max-width: 250px
          height: 100%
          position: fixed
          top: 0
          left: -250px
          z-index: 999999
          background-color: #fff
          padding: 30px 20px
          overflow: auto
          +t(.5)
          &::-webkit-scrollbar
            display: none
          &.in
            left: 0
        li
          list-style-type: none
          margin: 0 0 5px
          a
            display: block
            padding: 13px 45px
            border: solid 1px $main
            +font(1.125,$black,0)
              font-weight: normal
              text-decoration: none
            +tablet
              padding: 10px 20px
            &:hover,&.active
              background-color: $main
              color: #fff
      &+div
        width: 75%
        +tablet
          width: 100%
  .kc_row_inner
    margin-left: -10px
    width: calc(100% + 20px)
    &>div.kc_col-sm-2
      margin-bottom: 25px
      padding: 0 10px
      width: calc(100% / 3 - 0.1px)
      border-bottom: solid 1px $main
      +tablet
        width: 50%
      +phone
        width: 100%

  .kc_single_image
    a,img
      display: block
      width: 100%
      +t(.6)
    figure
      display: block
      overflow: hidden
    p
      padding: 10px 0 15px
      text-align: center
      +font(1,$black,0)
    &:hover
      img
        transform: scale(1.2)
        
        
