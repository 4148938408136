// 引入
@import "./_include/_include.sass"

// 文章
body.archive.category,body.search.search-results:not(.woocommerce-page)
  #content-inside
    +pg-container
    display: flex
    flex-wrap: wrap
    padding-top: 0px
  #primary
    width: 100%

  #main    
    display: flex
    flex-wrap: wrap

  .post
    display: flex
    flex-wrap: wrap
    margin-left: -8px
    width: calc(100% + 16px)
    border: none
    margin-bottom: 16px
    padding: 0
    &:nth-of-type(odd)
      flex-direction: row-reverse
    +tablet
      padding: 0 0 30px
      margin-bottom: 30px
      border-bottom: solid 1px #ccc
    .list-article-thumb
      display: block
      margin-right: 0
      width: calc(50% - 16px)
      margin: 0 8px
      +tablet
        width: 100%
        margin-bottom: 15px
        margin-left: 0
        margin-right: 0
      img,a
        display: block
        width: 100%
    .list-article-content
      display: flex
      flex-direction: column
      justify-content: center
      width: calc(50% - 16px)
      margin: 0 8px
      padding: 30px 7vw
      background-color: #EAEAEA
      +tablet
        width: 100%
        margin-left: 0
        margin-right: 0
    .list-article-meta
      margin-bottom: 0
      a
        +font(.9,$main,0)
        font-weight: normal
      span.date
        display: block
        +font(.85,$gray,0)
        font-weight: normal
    .entry-header
      margin-bottom: 15px
    span.date
      +font(0.875,$main,0)
        font-weight: normal
    .entry-title
      margin-bottom: 10px
      line-height: normal
      a
        +font(1.375,$main,0)
          font-weight: bold
          line-height: normal
    .entry-excerpt,.entry-summary
      p
        +font(1.125,$black,0)
      .clearfix
        +clearfix
      a.read-more
        display: block
        margin-top: 30px
        float: right
        text-decoration: none
        +font(1.25,$black,0)
        position: relative
        padding-left: 15px
        &:after
          content: ""
          display: block
          +size(70px,0)
          border-top: solid 1px $main
          position: absolute
          right: 100%
          bottom: 40%
        &:hover
          color: $main

.wp-pagenavi
  display: flex
  align-items: center
  justify-content: center
  flex-wrap: wrap
  width: 100%
  padding: 40px 0 50px
  *
    +font(1,$black,0)
  span:not(.pages),a:hover
    background-color: $main
    color: #fff
  span:not(.pages),a
    display: block
    margin: 0 3px
    +size(25px)
    border-radius: 50%
    text-align: center
    text-decoration: none
    line-height: 25px
    +t(.3)
  span.pages
    margin-right: 15px
  a.nextpostslink
    margin-left: 10px
  a.previouspostslink
    margin-right: 10px


body.single-post
  #content-inside
    +container
      // max-width: 900px
      padding-bottom: 80px
      +phone
        padding-bottom: 40px

  .entry-thumbnail
    img
      display: block
      width: 100%

  .entry-header
    .byline
      display: none

  .entry-footer,.entry-meta
    span,a
      +font(.9,$gray,.05)
      font-weight: normal
      
  .entry-content
    padding: 30px 0
    min-height: 160px
    +phone
      padding: 20px 0
      min-height: 120px
    section.kc_row
      padding: 0
      .kc-row-container.kc-container
        width: 100%
    p
      +font(1.125,$black,0)

  span.date
    +font(0.875,$main,0)
      font-weight: normal

  .goback
    display: block
    margin: 0 auto
    +font(1.125,$main,0)
    background-color: transparent
    border: none

  .entry-title
    margin-bottom: 10px
    +font(1.375,$main,0)
      font-weight: bold
      line-height: normal

  .relpost-thumb-wrapper
    float: left
    left: -6px
    position: relative
    width: calc( 100% + 12px )
    h3
      padding: 6px
      margin-bottom: 10px
      font-weight: normal
    .relpost-block-container
      width: 100%
      float: left
      a.relpost-block-single
        width: calc(100% / 3 - 0.1px)
        background: none
        +phone
          margin-bottom: 20px
          padding-right: 0
          width: 100%
      .relpost-custom-block-single,
      .relpost-block-single-image
        min-width: 100%
        height: auto!important
      .relpost-custom-block-single
        margin: 0
      .relpost-block-single-image
        padding-bottom: 63%
        background-size: cover!important
        background-position: center center!important
      .relpost-block-single-text
        padding-top: 10px
        text-align: left
        +font(0.9,$black,0)

.post-btns
  display: flex
  justify-content: space-between
  &>div
    width: 48%
    a
      display: block
      +font(1,$black,0)
      text-decoration: none
      span
        display: block
    &.next
      text-align: right

