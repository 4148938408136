// 引入
@import "./_include/_include.sass"

[id^="module-pg-design"]
  background-image: url('/wp-content/uploads/design_bg01.png')
  background-size: contain
  .kc-container.kc-row-container
    +container

  .kc_row_inner
    display: flex
    margin-left: -10px
    width: calc(100% + 20px)
    a
      margin: 0 10px
      margin-bottom: 30px
      width: calc(100% / 3 - 20.1px)
      text-decoration: none
      +tablet
        width: calc(50% - 20px)
      +phone
        width: 100%
      figure
        padding-bottom: 70%
        margin-bottom: 10px
        object-position: center bottom
        position: relative
        img
          display: block
          +size(100%)
          position: absolute
          top: 0
          left: 0
          object-fit: cover
      p
        +font(1,$black,0)
          text-align: center
          font-weight: normal
          line-height: normal
