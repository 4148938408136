// 引入
@import "./_include/_include.sass"

[id^="module-pg-about"]
  .kc_text_block:not(.page-title)
    p,li
      margin-bottom: 20px
      +font(1.25,$black,0)
        line-height: 28px
        font-weight: normal
        +phone
          line-height: 24px
    li
      margin: 0
    h3
      margin-bottom: 30px
      +font(1.5,$main,0)
      &:before,&:after
        content: ""
        display: inline-block
        margin: 0 25px 6px
        +size(8px)
        background-color: $main
        transform: rotate(45deg)

[id^="module-pg-about-one"]
  background-image: url(/wp-content/uploads/bg01.png)
  background-size: contain
  .kc-row-container.kc-container
    +pg-container
  .kc_row_inner
    display: flex
    align-items: flex-start
    .kc_single_image
      +tablet
        margin-bottom: 15px
      img
        display: block
        width: 100%
    .kc_text_block
      padding: 35px 55px 15px
      background-color: #fff
      margin-top: 65px
      border-radius: 3px
      box-shadow: 3px 3px 6px #00000029
      +tablet
        padding: 30px 30px 10px
        margin-top: 0
      ul
        margin: -20px 0 0
        padding: 0
        li
          margin: 0
          list-style-type: none

[id^="module-pg-about-two"]
  background-image: url('/wp-content/uploads/about_bg02.png')
  background-size: cover
  .kc_text_block:not(.page-title)
    h3,p
      color: #fff
    h3
      &:after,&:before
        background-color: #fff

[id^="module-pg-about-parallax"]
  padding: 0
  padding-bottom: 35%
  background-attachment: fixed
  background-size: contain
  +tablet
    background: none!important
    padding: 0
  .kc-container.kc-row-container
    display: none
    max-width: 100%
    width: 100%
    +tablet
      display: block


[id^="module-pg-about-three"]
  background-image: url('/wp-content/uploads/about_bg04.png')
  background-size: cover