// 引入
@import "./_include/_include.sass"

// header
.fa-angle-down:before
  +mobile
    display: none
    content: "+"

.onepress-menu .nav-toggle-dropdown > .nav-toggle-subarrow .fa-angle-down.fa-angle-down:before
  +mobile
    content: "-"!important

.header-full-width .container
  position: static

.site-header
  line-height: normal
  background: none
  overflow: visible!important
  +mask
  .header-right-wrapper
    float: right
  .container
    width: calc(100% - 192px)
    +mobile
      width: calc(100% - 60px)

#header-section
  background-color: rgba($main,.9)
  position: fixed
  top: 0
  left: 0
  width: 100%
  z-index: 2

#site-navigation
  +clearfix
  
.site-branding
  padding: 22px 70px 22px 0
  position: relative
  +t(.3)
  +tablet
    padding-top: 16px
    padding-bottom: 16px
  &:before,&:after
    content: ""
    display: block
    position: absolute
    top: 0
    right: 0
    background-color: #fff
    transform: skew(25deg)
  &:before
    width: 100vw
    height: 100%
  &:after
    +size(15px,100%)
    background-color: $main
    top: 0
    right: 15px
  +mobile
    &.switch-active
      opacity: 0
  .site-text-logo
    position: relative
    z-index: 2
    +font(0,transparent,0)
      display: block
      // line-height: 61px
    background-image: url(/wp-content/uploads/logo.svg)
    background-size: contain
    background-repeat: no-repeat
    background-position: center center
    +size(290px,35px)
    +tablet
      +size(190px,25px)

.main-navigation
  position: relative
  z-index: 99999
  .onepress-menu
    margin-right: -27px
    +mobile
      border: none
      // 初始版本
      // padding: 0
      // width: 100vw!important
      // right: -30px
      // margin-right: 0
      // 左側版本
      overflow-y: scroll
      padding-top: 20px
      position: fixed
      left: -250px
      top: 0
      height: 100vh
      max-width: 250px
      +t(.5)
      padding-bottom: 80px
      &::-webkit-scrollbar
        display: none
      &.switch-active
        left: 0
    // default
    li
      border: none
      +mobile
        width: 100%
      a
        text-decoration: none
        font-family: inherit
        line-height: normal
        border: none
        text-align: center
        +t(.3)
        span
          display: block
          margin-top: 5px
          +font(0.875,#fff,0)
          +mobile
            color: $main
      .nav-toggle-subarrow
        padding: 10px 30px
        +t(.3)
        +font(1,$black,.1)
          line-height: normal
        +mobile
          display: block
          border: none
          position: absolute
          top: 0
          right: 0
          width: 100%
          height: 58px
          text-align: right
          z-index: 2
          cursor: pointer
      &:hover
        .nav-toggle-subarrow
          color: #fff
      // 第二層
      ul.sub-menu
        border: none
        width: 150px
        min-width: 150px
        left: calc(50% - 75px)
        +mobile
          position: relative
          display: none
          top: 0
          left: 0
          width: 100%
          background-color: $main
        &>li
          background: transparent
          &>a
            +font(.85,$black,.05)
            padding: 7px 20px
            text-align: center
            +mobile
              background: transparent
              color: #fff
              padding: 10px 30px
            &:hover
              color: #fff
              background-color: $main
      &.nav-toggle-dropdown
        ul.sub-menu
          display: block
          animation: fade .3s both
    // 第一層
    &>li
      &>a
        padding: 17px 20px
        +font(1,#fff,0)
        +max(1240px)
          padding-left: 15px
          padding-right: 15px
        +max(1170px)
          padding-left: 10px
          padding-right: 10px
        +mobile
          padding: 10px 30px
          color: $main
        &.wpmenucart-contents
          padding: 22.5px 20px
          font-size: 12px
          letter-spacing: 1px
          +mobile
            padding: 12.5px 30px
      // &:hover>a
      //   color: #fff
      //   background-color: $main

#nav-toggle
  margin: 0
  position: absolute
  top: 50%
  right: 0
  z-index: 999999
  +size(35px)
  transform: translateY(-50%)
  span
    border-radius: 50px
    background-color: #fff
    &:before,&:after
      transform-origin: 50% 50%
      border-radius: 50px
      background-color: #fff
  &.nav-is-visible
    // background-color: rgba(#fff,.3)
    border-radius: 50%
    span
      &:before,&:after
        top: 0
        left: 0
        background-color: #fff
      &:before
        transform: rotate(45deg)
      &:after
        transform: rotate(-45deg)

#nav-button
  display: none
  margin: 0
  position: absolute
  top: 50%
  right: 0
  z-index: 1000
  transform: translateY(-50%)
  +size(35px)
  font-size: 0
  z-index: 999999
  +mobile
    display: block
  span
    display: block
    margin: 16px auto
    +size(25px,3px)
    background-color: #fff
    position: relative
    border-radius: 50px
    +t(.3)
    &:before,&:after
      content: ""
      display: block
      +size(25px,3px)
      background-color: #fff
      transform-origin: 50% 50%
      position: absolute
      left: 0
      +t(.3)
      border-radius: 50px
    &:before
      top: -8px
    &:after
      top: 8px
  &.switch-active
    span
      background-color: transparent
      &:before,&:after
        top: 0
        left: 0
        background-color: #fff
      &:before
        transform: rotate(45deg)
      &:after
        transform: rotate(-45deg)

.float-right-icons
  margin: 0
  padding: 0
  position: fixed
  top: 40%
  right: 30px
  z-index: 20
  +tablet
    right: 5px
  li
    margin-bottom: 20px
    list-style-type: none
    +tablet
      margin-bottom: 10px
    a
      display: block
      +size(47px,auto)
      +tablet
        +size(35px,auto)
      img
        display: block
        width: 100%