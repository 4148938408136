// 引入
@import "./_include/_include.sass"
  
.fade
  opacity: 0
  &.animated
    @keyframes fade
      0%
        opacity: 0
      100%
        opacity: 1
    animation: fade 1s both

.fadeDown
  opacity: 0
  &.animated
    @keyframes fadeDown
      0%
        transform: translateY(-100px)
        opacity: 0
      100%
        opacity: 1
        transform: translateY(0)
    animation: fadeDown 1s both
  
.fadeUp
  opacity: 0
  &.animated
    @keyframes fadeUp
      0%
        transform: translateY(100px)
        opacity: 0
      100%
        opacity: 1
        transform: translateY(0)
    animation: fadeUp 1s both

.fadeLeft
  opacity: 0
  &.animated
    @keyframes fadeLeft
      0%
        transform: translateX(-100px)
        opacity: 0
      100%
        opacity: 1
        transform: translateX(0)
    animation: fadeLeft 1s both

.fadeRight
  opacity: 0
  &.animated
    @keyframes fadeRight
      0%
        transform: translateX(100px)
        opacity: 0
      100%
        opacity: 1
        transform: translateX(0)
    animation: fadeRight 1s both

.fadeScale
  opacity: 0
  &.animated
    @keyframes fadeScale
      0%
        transform: scale(0.9) translateY(10px)
        opacity: 0
      100%
        opacity: 1
        transform: scale(1) translateY(0px)
    animation: fadeScale 1s both


@keyframes load
  0%
    transform: scale(.3)
    opacity: 0
  100%
    opacity: 1
    transform: scale(1)
      
