// 引入
@import "./_include/_include.sass"

// 預設值
*
  font-family: 'Microsoft Jhenghei', sans-serif

textarea:not(.editor-post-title__input):focus, input[type="date"]:focus, input[type="datetime"]:focus, input[type="datetime-local"]:focus, input[type="email"]:focus, input[type="month"]:focus, input[type="number"]:focus, input[type="password"]:focus, input[type="search"]:focus, input[type="tel"]:focus, input[type="text"]:focus, input[type="time"]:focus, input[type="url"]:focus, input[type="week"]:focus
  border-bottom-color: #efefef!important

input[type="radio"], input[type="checkbox"]
  margin-left: 0

select,.woocommerce-ordering select
  appearance: none
  padding: 5px 35px 5px 15px
  +font(.85,$gray,.1)
  background-image: url(/wp-content/uploads/down-filled-triangular-arrow.svg)
  background-size: 10px auto
  background-repeat: no-repeat
  background-position: calc(100% - 15px) center

h1,h2,h3,h4,h5,h6,p
  margin: 0
  font-family: 'Microsoft Jhenghei', sans-serif

.container
  margin: 0 auto

html
  font-size: 16px
  +phone
    font-size: 15px
  // +mini
  //   font-size: 12px

body
  // animation: fade .5s 1s both
  +mask
  &.overviwe-hidder
    overflow: hidden

#page
  overflow: hidden
  +tablet
    padding-top: 57px

.loading
  position: fixed
  top: 0
  left: 0
  +size(100%)
  background-color: #fff
  z-index: 1000
  display: flex
  align-items: center
  justify-content: center
  flex-direction: column
  .bar
    margin-bottom: 15px
    +size(150px,14px)
    background-color: #fff
    border: solid 1px $main
    border-radius: 50px
    position: relative
    &:after
      content: ""
      display: block
      +size(0px,8px)
      position: absolute
      top: 2px
      left: 2px
      background-color: $main
      border-radius: 50px
      +t(1)
      transition-timing-function: linear
  p
    color: $black
    font-size: 12px
    letter-spacing: 2px
  &.end
    .bar
      &:after
        width: 144px

.header-full-width .container
  padding: 0

.container,
.header-full-width .container
  +container

.container,
.kc-col-container
  +clearfix

.breadcrumbs
  span[property="name"]
    +font(.85,$black,0)
    font-weight: normal
    &.current-item
      color: $main

#content-inside
  max-width: 100%
  width: 100%


#content
  min-height: 0
  +t(.5)

#primary
  padding: 0

.entry-content
  margin-bottom: 0


// kim
.kc-container
  padding: 0

.kc-container,
.kc_column, .kc_column_inner
  min-height: 0

// .kc-row-container.kc-container .kc-wrap-columns, .kc_row_inner,
// .kc_row.kc_row_inner
  // width: 100%
  // margin: 0

#welcome
  display: flex
  align-items: center
  justify-content: center
  padding: 100px 0
  &>div
    +container
  
  .kc_text_block
    text-align: center

.site-content
  border-top: none


// 頁面標題
.page-header
  .container
    display: none
    *:last-child
      margin-bottom: 0
  .entry-title
    margin-bottom: 10px
    font-size: 25px
    // font-weight: 500
    letter-spacing: 5px
    line-height: normal
    +tablet
      font-size: 20px
      letter-spacing: 3px
  .entry-tagline
    font-style: normal
    p
      font-size: 16px
      margin: 0
      letter-spacing: 3px
      +tablet
        font-size: 14px
      +phone
        font-size: 12px
        letter-spacing: 1px
  &.page--cover
    display: flex
    flex-direction: column
    justify-content: center
    padding: 50px 0
    background-size: cover
    min-height: calc(100vw / 3)
    .entry-title
      margin-bottom: 10px
      font-size: 40px
      font-weight: 500
      letter-spacing: 5px
      line-height: normal
      +tablet
        margin-bottom: 5px
        font-size: 30px
      +phone
        font-size: 20px
    .entry-tagline
      font-style: normal
      p
        margin: 0
        letter-spacing: 3px

// 區塊框架
.bg
  background-color: rgba($gray,.2)

// 手機版、電腦版
*.mobile
  display: none
  +tablet
    display: block
*.computer
  +tablet
    display: none

[id^="module-"]
  padding: 45px 0
  +tablet
    padding: 30px 0
  &.no-padding-bottom
    padding-bottom: 0
  &.no-padding-top
    padding-top: 0
  .kc-row-container.kc-container
    +container

// #tab-description
// .woocommerce-product-details__short-description
.kc_text_block.title
  hr
    max-width: 720px
  h2
    display: flex
    align-items: center
    justify-content: center
    span
      display: block
      margin: 0 10px
      +size(8px)
      background-color: $main
      transform: rotate(45deg)

.kc_text_block.more
  p
    a
      +more

.kc_text_block.default
  h1
    +font(2,$black,.25)
      padding: 30px 0
    img
      // margin-top: 30px
      // margin-bottom: 30px
      max-width: 100%
      &.full
        width: 100%
  h2
    margin-bottom: 20px
    +font(1.375,$black,0)
    &+h3
      +font(1.25,$gray,.1)
        padding-bottom: 0
        margin-top: -15px
        font-weight: normal
  h3
    +font(1.5,$black,.15)
      margin-bottom: 25px
  h4
    +font(1.3,$black,.1)
      margin-bottom: 15px
  h5
    +font(1.1,$black,.1)
      margin-bottom: 15px
  h6
    +font(1,$black,.1)
      margin-bottom: 15px
  p,li
    margin-bottom: 20px
    +font(1.125,$black,0)
  p
    margin-bottom: 15px
    &+*:not(p)
      margin-top: 30px
  hr
    margin-bottom: 20px
    border-top: dashed 2px $main
    
div.kc_row_inner
  display: flex
  flex-wrap: wrap
  .kc_text_block
    padding-bottom: 35px
    +tablet
      padding: 30px 0
    +phone
      padding: 20px 0

.kc-video-inner
  position: relative
  padding-bottom: 56.2%
  iframe
    position: absolute
    top: 0
    left: 0
    +size(100%!important)

.kc-wrap-columns
  +clearfix

// 搜尋樣式
.search-form,.woocommerce-product-search
  display: flex
  label
    display: block
    width: calc(100% - 40px)
    flex-grow: inherit
    -webkit-box-flex: inherit
    &.screen-reader-text
      display: none

  input[type="search"]
    padding: 8.5px 15px
    +font(1,$black,0)
    width: calc(100% - 40px)
    outline: none
  [type="submit"]
    margin: 0
    padding: 0
    font-size: 0
    +size(40px)
    background-color: $main
    border: none
    background: $main url(/wp-content/uploads/magnifying-glass.svg) no-repeat center center
    background-size: 20px auto
    &:hover
      background: $main url(/wp-content/uploads/magnifying-glass.svg) no-repeat center center
      background-size: 20px auto
      opacity: 1
      
div.pp_pic_holder.pp_pic_holder
  top: 50%!important
  left: 50%!important
  transform: translate(-50%,-50%)
  position: fixed
  width: auto!important
  max-width: 900px
  img,.pp_content,.pp_hoverContainer,.pp_details
    display: block
    width: 100%!important
    height: auto!important
    max-height: 80vh
    max-width: 100%
    object-fit: contain
  a
    text-decoration: none

.pp_content_container.pp_content_container
  .pp_left,.pp_right
    padding-left: 0
    padding-right: 0

.fancybox-slide--video
  .fancybox-content
    margin: 0
    height: 0
    max-height: 0
    position: relative
    padding-bottom: 45%
    iframe
      position: absolute
      top: 0
      left: 0    

.c4wp-display-captcha-form.c4wp-display-captcha-form
  display: flex
  flex-direction: row-reverse
  justify-content: flex-end
  position: relative
  width: 200px
  br,strong
    display: none
  .refresh_captcha
    display: block
    +size(15px)
    position: absolute
    left: calc(100% + 5px)
    bottom: 50%
    transform: translateY(50%)
    img
      display: block
      margin: 0
      float: none
      +size(100%,auto )
  input[type="text"].c4wp_user_input_captcha
    margin: 0
    padding: 10px
    font-size: 1rem
    width: 100px
    &:focus
      outline: none