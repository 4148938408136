//color
$main: #0046B5
$black: #242424
$gray: #707070
$text: #333
$link: #0d3f67
$special: #cb3747
$note: #8fae1b


=mobile($mobile:1140px)
  @media screen and (max-width: $mobile)
    @content

=tablet($tablet:768px)
  @media screen and (max-width: $tablet)
    @content

=phone($phone:640px)
  @media screen and (max-width: $phone)
    @content

=mini($min:500px)
  @media screen and (max-width: $min)
    @content

=max($size)
  @media screen and (max-width: $size)
    @content

=min($size)
  @media screen and (min-width: $size)
    @content

//mixin
=size($w,$h:$w)
  width: $w
  height: $h

=clearfix  
  &:after
    content: ""
    display: table
    clear: both

=t($t:0.5)
  transition-duration: #{$t}s

=clr($c:#222)  
  color: $c

=font($size:1,$color:#000,$space:.2)
  font-size: #{$size}rem
  color: $color
  letter-spacing: #{$space}rem
  @content
  // +tablet
  //   font-size: #{$size*.95}rem
  //   letter-spacing: #{$space*.95}rem
  // +phone
  //   font-size: #{$size*.9}rem
  //   letter-spacing: #{$space*.9}rem
  // +mini
  //   font-size: #{$size*0.75}rem
  //   letter-spacing: #{$space*.75}rem
  
=container
  display: block
  margin: 0 auto
  padding: 0
  max-width: 1280px
  width: calc(100% - 380px)
  +mobile
    width: calc(100% - 60px)
  @content
  
=pg-container
  display: block
  margin: 0 auto
  padding: 0
  max-width: 1280px
  width: calc(100% - 192px)
  +mobile
    width: calc(100% - 60px)
  @content

=animate_delay($n:4,$cut:0.2)
  @for $i from 0 through 100
    &:nth-of-type(#{$i+1})
      animation-delay: #{$i%$n*$cut}s

=mask
  &:before
    content: ""
    display: none
    position: fixed
    top: 0
    left: 0
    width: 100%
    height: 100%
    background-color: rgba(#000,.7)
    z-index: 99999
  &.switch-active
    overflow: hidden
    &:before
      display: block

=more
  +font(1.125,$black,0)
    text-decoration: none
    font-weight: bold
  &:after,&:before
    content: ""
    display: inline-block
    margin-top: 2px
    margin-left: 5px
    margin-right: 5px
    +size(10px)
    background-image: url(/wp-content/uploads/deco_more.svg)
    background-size: contain
    background-position: center center
    background-repeat: no-repeat
  &:after
    transform: rotate(180deg)