// 引入
@import "./_include/_include.sass"

.kc_text_block
  &.page-title
    display: block
    width: fit-content
    float: none
    margin-left: auto
    margin-right: auto
    margin-bottom: 45px
    margin-left: auto
    margin-right: auto
    position: relative
    padding-left: 40px
    padding-right: 40px
    +tablet
      margin-bottom: 30px
      padding-left: 20px
      padding-right: 20px
    h2
      margin-bottom: 10px
      +font(1.375,$main,0)
        font-weight: bold
      +tablet
        margin-bottom: 5px
    p
      margin-bottom: 0
      +font(1.125,$black,0)
        font-weight: bold
    &:after,&:before
      content: ""
      display: block
      +size(180px,0)
      border-top: dashed 2px $main
      position: absolute
      bottom: calc(50% - 1px)
      +tablet
        width: 100px
      +phone
        width: 50px
    &:after
      left: 100%
    &:before
      right: 100%

body.single-post,
body.archive.category
  .kc_text_block.page-title
    text-align: center
    padding: 45px 40px
    margin-bottom: 0
    h2
      font-size: 0
      span      
        +font(1.375,$main,0)
          font-weight: bold


.pagecounts.btns
  +container
  display: flex
  align-items: center
  justify-content: center
  padding: 40px 0
  +tablet
    padding: 20px 0
  span
    cursor: pointer
    +font(1.125,$main,0)
      font-weight: bold
      line-height: normal
  ul
    display: flex
    justify-content: center
    align-items: center
    margin: 0 15px
    padding: 0
    width: fit-content
    li
      margin: 0
      list-style-type: none
      cursor: pointer
      +font(1.125,$black,0)
        font-weight: bold
        line-height: normal
      &.active
        text-decoration: underline
      &:after
        content: ","
        margin: 0 5px
      &:last-of-type:after
        display: none