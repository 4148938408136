// 引入
@import "./_include/_include.sass"


[id^="module-carousel"]
  padding: 0
  .kc-row-container.kc-container
    display: none
  .swiper-slide
    display: block
    position: relative
    +t(1)
    +tablet
      // padding-bottom: 150px
      // background-image: linear-gradient(to bottom,$main 60%,transparent 100%)
    img
      display: block
      width: 100%
    .content
      padding: 30px
      border-radius: 5px
      max-width: 600px
      width: calc(100% - 60px)
      background-color: rgba(#fff,.8)
      position: absolute
      bottom: calc(50% - 79px)
      right: 50%
      opacity: 0
      +t(1)
      transition-delay: .5s
      transform: translate(50%,50%)
      +tablet
        background-color: rgba(#fff,1)
      h3
        margin-bottom: 15px
        +font(1.25,$black,0)
      hr
        margin: 10px 0
        border-top: solid 1px $black
      ul
        display: flex
        flex-wrap: wrap
        margin: 0
        padding: 0
        li
          position: relative
          list-style-type: none
          margin: 0
          padding: 0 0 0 20px
          width: 50%
          +font(1,$black,0)
          a
            +font(1,$black,0)
          &:before
            content: ""
            display: inline-block
            +size(8px)
            background-color: $main
            transform: rotate(45deg)
            position: absolute
            left: 2px
            top: 10px
            +tablet
              top: 7px
    &.swiper-slide-active
      .content
        opacity: 1
        bottom: 45%
        +tablet
          bottom: 30%
  .swiper-button-next,
  .swiper-button-prev
    top: auto
    bottom: 20px
    +size(30px)
  .swiper-button-next
    right: calc(50% - 40px)
    background-image: url(/wp-content/uploads/right-arrow.svg)
  .swiper-button-prev
    left: calc(50% - 40px)
    background-image: url(/wp-content/uploads/left-arrow.svg)



[id^="module-idx-about"]
  .kc-container.kc-row-container
    max-width: 720px
  
  .kc_text_block
    ul
      display: flex
      flex-wrap: wrap
      justify-content: center
      list-style-type: none
      padding: 0
      margin: 0 auto
      width: calc(100% - 50px)
      li
        margin: 0 0 15px
        width: fit-content
        flex-shrink: 0
        +phone
          margin-left: 15px
          margin-right: 15px
        &:after
          content: "、"
          +phone
            display: none
        &:last-of-type
          &:after
            display: none
    p
      margin-left: auto
      margin-right: auto
      width: calc(100% - 50px)
      span
        &::after
          content: "、"
        &:last-of-type
          &:after
            display: none
      br
        display: none
        +tablet
          display: block
      a
        +more

[id^="module-idx-news"]
  padding-top: 0
  +tablet
    padding-bottom: 0
  .list-post-title
    width: fit-content
    padding-bottom: 20px
    margin-bottom: 20px
    border-bottom: dashed 2px $main
    line-height: 30px
    +font(1.375,$black,0)
    span
      display: block
  .kc-row-container.kc-container
    background-color: #EFEFEF
    border-radius: 5px
    padding: 55px 70px
    max-width: 1040px
    box-shadow: 0px 3px 6px #00000029
    width: 100%
    +phone
      padding: 30px
  .list-item
    padding: 0
    margin: 0
    border: none
  .post-content
    // display: flex
    // flex-direction: row-reverse
    // flex-wrap: wrap
    // align-items: flex-start
    position: relative
    +clearfix
    .text
      min-height: 60px
      // display: none
      +font(1,$black,0)
    figure,h3,.text
      width: 50%
      margin: 0
      float: none
      +tablet
        width: 100%
      img
        display: block
        width: 100%
    figure
      padding-left: 7vw
      margin-top: -100px
      position: absolute
      top: 0
      right: 0
      +tablet
        margin: 0
        position: relative
        padding-left: 0
    h3
      padding-top: 30px
      margin-bottom: 10px
      padding-right: 7vw
      +tablet
        padding-right: 0
      +phone
        padding-top: 15px
      a
        +font(1.125,$main,0)
          line-height: 24px
        &:hover
          color: $main
          text-decoration: none
    .kc-entry_meta
      top: 0
      left: 0
      position: absolute
      padding: 0
      +tablet
        position: relative
      .date
        +font(0.875,$gray,0)
        i
          display: none

[id^="module-idx-design"]
  background-image: url(/wp-content/uploads/bg01.png)
  background-size: contain
  // background-position: center center
  .kc-container.kc-row-container
    max-width: 900px
  .kc_row_inner
    display: flex
    flex-wrap: wrap
    margin-left: -30px
    width: calc(100% + 60px)
    +tablet
      width: calc(100% + 30px)
      margin-left: -15px
    &>div
      width: calc(100% / 3 - 0.1px)
      padding: 0 30px
      +tablet
        width: 50%
        padding: 0 15px
      +phone
        width: 100%
    .kc_text_block
      h5
        margin-bottom: 20px
        a
          display: block
          img
            display: block
            width: 100%
      h3
        background-color: $main
        border-radius: 100px
        box-shadow: 0px 3px 3px #00000029
        +t(.3)
        a
          display: block
          padding: 15px 0
          +t(.3)
          +font(1,#fff,0)
            text-decoration: none
        &:hover
          background-color: #CCCCCC
          a
            color: $main

[id^="module-idx-contact"]
  .kc_row_inner
    .kc_text_block
      padding-top: 0
      h2
        line-height: 36px
        &+hr
          width: 80px
          margin: 0 0 25px
      h3
        margin-bottom: 15px
        +font(1.25,$black,0)
      ul
        margin: 0
        padding: 0
        li
          list-style-type: none
          margin: 0
          a
            +font(1.125,$black,0)
  .kc-google-maps
    position: relative
    // &:after
    //   content: ""
    //   display: block
    //   position: absolute
    //   top: 0
    //   left: 0
    //   +size(100%)
    //   background-color: rgba(#fff,.3)
    //   -webkit-backdrop-filter: blur(3px)
    //   backdrop-filter: blur(3px)
    //   background-image: url(/wp-content/uploads/map_logo.svg)
    //   background-repeat: no-repeat
    //   background-position: center center
    //   pointer-events: none
    //   +t(.5)
    // &:hover
    //   &:after
    //     opacity: 0

[id^="module-idx-equipment"]
  background-size: cover
  background-position: center center
  background-image: url(/wp-content/uploads/bg02.png)
  .kc_row_inner
    margin-left: -6px
    width: calc(100% + 12px)
    &>div
      padding: 6px
      +tablet
        width: 50%

  .kc_single_image
    position: relative
    a
      display: block
    p
      display: flex
      align-items: center
      justify-content: center
      flex-direction: column
      background-color: rgba(#000,.5)
      line-height: normal
      margin: 0
      position: absolute
      bottom: 0
      left: 0
      opacity: 0
      padding: 15px
      pointer-events: none
      +size(100%)
      +t(.5)
      +font(1.125,#fff,0)
        font-weight: bold
      +tablet
        align-items: flex-start
        justify-content: flex-end
        opacity: 1
        background-image: linear-gradient(to top,rgba(#000,.8) 0,transparent 35%)
        background-color: transparent
      &:after
        content: ""
        display: block
        +size(40px,3px)
        background-color: #fff
        margin: 12px auto 0
        +tablet
          margin-left: 0
          margin-top: 8px
          height: 2px
    &:hover
      p
        opacity: 1