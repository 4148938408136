// 引入
@import "./_include/_include.sass"

[id^="module-pg-contact"]
  .kc-container.kc-row-container
    overflow: hidden
    +container

  .wpcf7
    margin-left: -8px
    width: calc(100% + 16px)

  .wpcf7-form
    display: flex
    flex-wrap: wrap

  .kc-contact-form7
    max-width: 720px
    float: none
    margin: 0 auto
    padding: 45px 0 90px
    +tablet
      padding-bottom: 45px
    +phone
      padding: 30px 0
    label
      margin: 0 0 5px
      +font(1.125,#242424,0)
        font-weight: normal
    input,select,textarea
      background-color: #EAEAEA
      border: solid 1px #707070
      padding: 13px 20px
      width: 100%
      +font(1.125,#242424,0)
        line-height: 20px
        font-weight: normal
  
    .input-row
      padding: 0 8px
      margin-bottom: 15px
      width: 100%
      &.col-6
        width: 50%

  .btn
    display: flex
    width: 100%
    padding: 0 8px
    border: none
    box-shadow: none
    &.center
      justify-content: center
      align-items: center
    input
      padding: 13px 40px
      width: fit-content
      background-color: $main
      color: #fff
      border: none

  .wpcf7-response-output
    width: 100%
    text-align: center

  .kc_text_block:not(.page-title)
    padding-top: 0
    float: none
    p,li
      +font(1.125,$black,0)
        font-weight: bold
    h3
      +font(1.1375,$main,0)
      &+hr
        border-top: dashed 2px $main
        width: 80px
        margin: 20px 0
    ul
      margin: 0
      padding: 0
      li
        list-style-type: none
        margin: 0
        font-weight: normal