// 引入
@import "./_include/_include.sass"

[id^="module-pg-work"]
  .kc-container.kc-row-container
    overflow: hidden
    +container

  .kc-col-inner-container
    overflow: hidden

  .kc_row.kc_row_inner:not(.pages):last-of-type
    margin-bottom: -26px
    


  .kc_row_inner
    margin-left: -10px
    width: calc(100% + 20px)
    &>div.kc_col-sm-2
      margin-bottom: 25px
      padding: 0 10px
      width: calc(100% / 3 - 0.1px)
      border-bottom: solid 1px $main
      +tablet
        width: 50%

  .kc_single_image
    a,img
      display: block
      width: 100%
      +t(.6)
    figure
      display: block
      overflow: hidden
    p
      padding: 10px 0 15px
      text-align: center
      +font(1,$black,0)
    &:hover
      img
        transform: scale(1.2)
        
        
