// 引入
@import "./_include/_include.sass"

// 左側
.menu-toggle-btn
  +font(.9,#fff,0)
  display: none
  line-height: normal
  padding: 8px 3px
  text-align: center
  position: fixed
  left: 0
  top: 40%
  width: 25px
  background-color: $main
  border: none
  outline: none
  +t(.5)
  z-index: 999
  +tablet
    display: block
  span:last-of-type
    display: none
  &.toggle
    left: 250px
    z-index: 999999
    span
      display: none
    span:last-of-type
      display: block

#shop-menu,#secondary
  padding: 0
  width: 250px
  position: relative
  z-index: 2
  +t(.5)
  +tablet
    position: fixed
    padding: 30px 20px
    background-color: #fff
    height: 100%
    top: 0
    left: -250px
    overflow: auto
    &::-webkit-scrollbar
      display: none
    &.in
      left: 0
      z-index: 999999
  .widget
    margin-bottom: 30px
  .widget-title
    +font(1.2,$gray,.05)
      font-weight: 500
      margin-bottom: 20px
      border-left: solid 3px $gray
      padding-left: 10px
  .product-categories,.widget_categories>ul
    margin: 0
    padding: 0
    &>li
      width: 100%
      &>a
        border-bottom: solid 1px #eee
    li
      margin: 0
      list-style-type: none
      a
        +font(1,$black,.05)
          display: block
          padding: 5px 0px
          text-decoration: none
          +t(.3)
          &:hover
            color: $main
      &.cat-parent
        cursor: pointer
        position: relative
        &>a
          +clearfix
        &>span
          +font(1,$black,.05)
            display: block
            padding: 5px 0
            position: absolute
            top: 0
            right: 5px
            width: 30px
            text-align: center
          &:after
            content: "+"
      &.current-cat
        &>a,&>span
          color: $main
      &.current-cat-parent
        &>span:after
          content: "-"
        &>.children
          // display: block
      .children
        display: none
        padding: 10px 0
        min-height: 1px
        li
          a
            +font(.9,$black,0)
            padding: 0
            &:hover
              color: $main
            &:before
              content: "-"
              margin-right: 5px
          &.current-cat
            &>a
              color: $main

  .widget_recently_viewed_products
    .product_list_widget
      li
        display: flex
        flex-wrap: wrap
        justify-content: flex-end
        a
          display: flex
          align-items: flex-start
          width: 100%
          margin-bottom: -20px
          span
            display: block
            padding-bottom: 20px
            +font(.8,$black,0)
            font-weight: normal
            margin-left: 10px
            width: calc(100% - 60px)
          img
            display: inline-block
            position: relative
            float: none
            +size(50px,auto)
        // del,ins,.amount
        //   margin-top: -20px
        ins
          text-decoration: none
          *
            color: $special

  .widget_recent_entries
    ul
      li
        margin: 0
        padding: 5px 0
        a
          +font(1,$black,0)
        .post-date
          display: inline-block

